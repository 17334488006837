import type {
  DiscountType,
  ProductSelection,
  ShopCartUpdateActionType,
} from '@ecomm/graphql/types.generated';
import type {
  AccessoryBundleCommercetools,
  AccessoryCommerceTools,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { Accessory } from '@ecomm/pg-shop-accessories/models';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration';
import type { CfuCartAnalyticsModel } from '@ecomm/shop-configuration/models';

export type BundleObject = {
  bundle: AccessoryBundleCommercetools;
  productSelection: ProductSelection[];
};
export type CartItem = {
  type: Type;
  sku: string;
  bundleObject: CartBundleItem | undefined;
  quantity?: number;
};
type MutationItem = {
  quantity: number;
  sku: string;
};
export type MutationAction = {
  actionType: ShopCartUpdateActionType;
  addLineItem?: MutationItem;
  addBundleItem?: {
    bundleItems: MutationItem[];
    bundlePriceOffsetInCents: number;
    bundleDiscountInCents: number;
    sku: string;
  };
  addDiscount?: {
    code: string;
    type: DiscountType;
  };
  setIdMeVerificationId?: {
    idMeVerificationId: string;
  };
};
export type Upsell = VariantCommerceTools | BundleObject;
export type CartBundleItem = BundleObject | CfuCTPackage;
export type CfuCTPackage = {
  isSelectionValid: boolean;
  package: CommercetoolsPackage;
  productSelection: ProductSelection[];
  upsell?: Upsell[];
  analyticsProperties: Omit<CfuCartAnalyticsModel, 'cartId'>;
};

export enum Type {
  ACCESSORY = 'accessory',
  BUNDLE_ACCESSORY = 'bundle_accessory',
  CFU = 'cfu',
  CT_CFU = 'ct_cfu',
  REFERRAL = 'referral',
  SPARE_PART = 'spare_part',
  CFU_HAULAWAY = 'cfu_haulaway',
}

export type Product = {
  product_option_id: string;
  quantity: string[];
};

export type Bundle = {
  bundle_id: string;
  product_options: string[];
};

export type MonolithPayload = {
  products?: Product[];
  bundles?: Bundle[];
  renders_light_cart: boolean;
};

type AccessoryType = AccessoryCommerceTools | Accessory;

export type ItemType = AccessoryType;

export enum Connection {
  apple = 'apple',
  google = 'google-oauth2',
}

export type BillingAddressForm = {
  firstName?: string;
  lastName?: string;
  street1: string;
  postalCode: string;
  phone?: string;
  city: string;
  state?: string;
  street2?: string;
  country?: string;
};

export type ShippingAddressForm = {
  firstName: string;
  lastName: string;
  street1: string;
  postalCode: string;
  phone: string;
  city: string;
  state?: string;
  street2?: string;
  country?: string;
  isValidated?: boolean;
};

export type CheckoutFormsType = {
  shouldAllowMarketing: boolean;
  email: string;
  giftRecipientEmail?: string;
  gifterName?: string;
  giftMessage?: string;
  isBillingSameAsShipping: boolean;
  shippingAddress: ShippingAddressForm;
  billingAddress: BillingAddressForm;
};
